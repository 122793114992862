import Navbar from "react-bootstrap/Navbar";
import { Nav, Container, NavDropdown } from "react-bootstrap";
import logo from "../../images/Store-Berry-Logo1.png";
import "./style.css";

const NavBar = () => {
  return (
    <Navbar expand="lg" bg="white" className="boxShadaw p-3">
      <Container>
        <Navbar.Brand href="/">
          <img alt="logo" src={logo} width={200} height='80' />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav>

            <NavDropdown title="Candles" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Candles</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Candles 1
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Candles 2</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Gifts" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Gifts</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Gifts 1
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Gifts 2</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Stationery" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Stationery</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Stationery 1
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Stationery 2</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Personal Care" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Beauty</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Beauty 1
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Beauty 2</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Clothing" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Clothing</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Clothing 1
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Clothing 2</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Hampers" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Hampers</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Hampers 1
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Hampers 2</NavDropdown.Item>
            </NavDropdown>
            {/* <NavDropdown title="Home Decor and Ornaments" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Home Decor</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Home Decor 1
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Home Decor 2</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Art and Crafts" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Art and Crafts</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Art and Crafts 1
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Art and Crafts 2</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Electronics" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Electronics</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Electronics 1
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Electronics 2</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Jewelry" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Jewelry</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Jewelry 1
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Jewelry 2</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Footwear" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Men</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Women
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Women 2</NavDropdown.Item>
            </NavDropdown> */}

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
