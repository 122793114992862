import "./style.css";
import { Container, Table, Row, Button,Card } from "react-bootstrap";
import { connect } from "react-redux";
import { useEffect, useState } from "react";

function AboutUs(props) {
  return (
    <div className="productSlider mb-5 mt-5">
      <Container>
        <h5 className="text-left mb-4 ps-2">About Us</h5>
        <Row>
          <div className="col-9 cartShow">
          Welcome to StoreBerry.co - Your Destination for Quality and Style!

At StoreBerry.co, we invite you to embark on a journey of discovery where quality meets style. We are more than just an e-commerce website; we are your go-to destination for an unparalleled shopping experience, offering a curated selection of products designed to elevate your lifestyle.

Our Commitment:

At the heart of StoreBerry.co lies a commitment to delivering excellence. We take pride in sourcing products that seamlessly blend functionality, innovation, and aesthetics. Every item featured on our platform is carefully chosen to ensure it not only meets but exceeds your expectations.

A World of Choices:

Dive into a world of choices where variety meets quality. Whether you're exploring the latest trends in fashion, enhancing your home with stylish decor, or seeking unique accessories that make a statement, StoreBerry.co has you covered. Our extensive range caters to diverse tastes and preferences, ensuring there's something for everyone.

Customer-Centric Approach:

At StoreBerry.co, you are more than just a customer; you are a valued member of our community. Our customer-centric approach is designed to make your shopping experience seamless and enjoyable. From user-friendly navigation to responsive customer support, we prioritize your satisfaction at every step.

Quality Assurance:

We understand the importance of trust when it comes to online shopping. That's why we go the extra mile to guarantee the quality of every product we offer. From the materials used to the craftsmanship involved, our commitment to excellence ensures you receive products that stand the test of time.

Stay Connected:

Join our community and stay connected with the latest trends, exclusive offers, and exciting updates. Follow us on social media and subscribe to our newsletter to be the first to know about new arrivals and special promotions.

Explore, Discover, Shop:

Ready to elevate your shopping experience? Explore our virtual aisles, discover products that resonate with your style, and shop with confidence at StoreBerry.co. We are here to redefine the way you shop online, making every purchase a delightful and satisfying experience.

Thank you for choosing StoreBerry.co – Where Quality Meets Style!
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default AboutUs;
