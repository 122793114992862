import "../../style.css";
import { Col, Container, Row, Button, Form, } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { FooterLink } from "../footer/style";
import { EnvelopeFill, HeartFill, PhoneFill } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect, useState } from "react";

function TopHeader({ cart }) {
    //const wishTotal = useRecoilState(wishState);
    const wish = useSelector((state) => state.rw.wish);
    const [CartCounter, setCartCounter] = useState(0);
    const [WishCounter, setWishCounter] = useState(0);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const SignUp = (props) => {

        return (
            <Modal show={show} onHide={handleClose}>

                <Modal.Header closeButton>
                    <Modal.Title>Sign Up</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="name@example.com" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Phone1</Form.Label>
                            <Form.Control type="text" placeholder="Phone number" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Phone2</Form.Label>
                            <Form.Control type="text" placeholder="Phone number" />
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    useEffect(() => {
        let cartCount = 0;
        cart.forEach((item) => {
            cartCount += item.qty;
        });
        setCartCounter(cartCount);
        let wishCount = 0;
        wish.forEach((item) => {
            wishCount += item.toCountWish;
        });
        setWishCounter(wishCount);
    }, [cart, CartCounter, wish, WishCounter]);

    return (
        <div>
            <section className="header-top bg-pink text-white p-2 sticky-top">
                <Container>
                    <Row>
                        <Col className="text-start">
                            <FooterLink className="me-5">
                                <EnvelopeFill className="me-2"></EnvelopeFill>
                                sanmca1988@gmail.com
                            </FooterLink>
                            <FooterLink>
                                <PhoneFill className="me-2"></PhoneFill>
                                8248140890
                            </FooterLink>
                        </Col>
                        <Col className="text-end">
                            <Link className="me-5 FooterLink text-white" to="/wishList">
                                <FooterLink>
                                    <HeartFill className="me-2"></HeartFill>
                                    Wish List
                                    <span className="ms-1">({WishCounter})</span>
                                </FooterLink>
                            </Link>
                            <Link className="me-5 FooterLink text-white" to="/shoppingCart">
                                <FooterLink>
                                    <HeartFill className="me-2"></HeartFill>
                                    Shopping Cart
                                    <span className="ms-1">({CartCounter})</span>
                                </FooterLink>
                            </Link>
                            <Button variant="secondary" onClick={() => handleShow()}>Sign Up</Button>
                        </Col>
                    </Row>
                </Container>
            </section>
            <SignUp />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        cart: state.rCart.cart,
    };
};

export default connect(mapStateToProps)(TopHeader);
