import "./style.css";
import { Container, Table, Row, Button,Card } from "react-bootstrap";
import { connect } from "react-redux";
import { useEffect, useState } from "react";

function ContactUs(props) {
  return (
    <div className="productSlider mb-5 mt-5">
      <Container>
        <h5 className="text-left mb-4 ps-2">Contact US</h5>
        <Row>
          <div className="col-9 cartShow">
            <Card style={{ width: '18rem' }}>
        <Card.Body>
            <Card.Title>Contact 1</Card.Title>
            <Card.Text>
             <div>Name: Sanjeev</div>
             <div>Phone: 8989878787</div>
             <div>Email: sanmca1988@gmail.com</div>
            </Card.Text>
            <Card.Text>
             <div>Name: Sanjeev1</div>
             <div>Phone: 8989878787</div>
             <div>Email: sanmca1988@gmail.com</div>
            </Card.Text>
        </Card.Body>
        </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default ContactUs;
